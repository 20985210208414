/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
// import 'bootstrap' // directement déclaré dans le fichier de config webpack base
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'
import 'lazysizes'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
// import 'bootstrap/scss/bootstrap'
import './scss/_bootstrap_custom'

// Styles template
// import 'scss/style.scss'

// Styles librairies
// import 'toastr/toastr.scss'

// Styles custom
import './scss/custom'

// Scripts librairies
import 'waypoints/lib/jquery.waypoints.min.js'
import 'script-loader!./js/libs/misc'
import 'script-loader!./js/libs/utils' // lazy loading https://github.com/aFarkas/lazysizes
/* global lazySizes */
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  var bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Scroll to top
async function getScrollToTop () {
  const { default: _ } = await import('lodash')
  import('./js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-up"></i>'
    })
  })
}

// sticky header
function sticky () {
  var $s = $('.sticky-top')
  if ($(window).scrollTop() > 100) { $s.addClass('nav-bg') } else { $s.removeClass('nav-bg') }
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  // smooth scroll js
  $(window).scroll(function () {
    if ($(this).scrollTop() >= 100) { getScrollToTop() }
    sticky()
  })

  sticky()
})
