// Charge la base commune
import 'bootstrap/js/dist/scrollspy'

// Styles librairies
import './js/libs/slick-1.8.1/slick/slick.css'

// Styles custom
import './scss/pages/home'

// Scripts librairies
import 'script-loader!./js/libs/slick-1.8.1/slick/slick.js'

require('./common.js')

// Contact Form
async function getContactForm ($f) {
  const { default: _ } = await import('lodash')
  import('./js/components/contact-form.js').then(() => {
    $f.load(false)
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // ancre
  $('.scroll').each(function () {
    var $this = $(this)
    $this.on('click', function (e) {
      var t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($this.length > 0) {
        $('html, body').animate({
          scrollTop: $(t).offset().top + 5
        }, 800)
      }
    })
  })

  // banner img slide js part
  $('.banner-main').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    prevArrow: '.slidPrv',
    nextArrow: '.slidNext',
    arrows: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 2000,
    fade: true,
    cssEase: 'linear'
  })

  // market slide js
  $('.market-main').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 321,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  })

  // gestion du formulaire de contact
  var $f = $('.contact-form')
  if ($f.length) {
    $f.waypoint(function () {
      $f.load()
      getContactForm($f)
      this.destroy()
    }, {
      offset: '100%'
    })
  }
})
